.App {
  text-align: center;
  background-color: #000000;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  position: absolute;
  top: 10px;
  left: 50px;
  height: 150px;
  width: auto;
  z-index: 101;
}

.App-header {
  background-color: #0d0d0d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: sticky;
  height: 60px;
  top: 0;
  z-index: 100;
  overflow: visible;
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header-nav a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.header-nav a:hover,
.header-actions button:hover {
  background-color: white;
  color: #0d0d0d;
}

.App-main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 120px;
  overflow: auto; /* Enables scrolling if the content overflows */
}

.App-footer {
  background-color: #0d0d0d;
  color: white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 120px; /* Define the height of your footer */
  box-sizing: border-box; /* Ensure padding is included in the height */
}


@media (max-width: 768px) {
  .App-logo {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 90px;
    width: auto;
  }

  .App-header {
    background-color: #0d0d0d;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: sticky;
    height: 140px;
    top: 0;
    z-index: 100;
    overflow: visible;
  }

  .header-nav {
    display: block;
    padding-top: 100px;
  }

  .App-footer {
    padding: 5px;
    width: 100%;
    height: 100px; /* Define the height of your footer */
  }
}