.contact-page {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form input[type="text"],
.contact-form textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form label {
  margin-right: 10px;
}

.contact-form .submit-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.contact-form .submit-btn:hover {
  background-color: #0056b3;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
  color: white;
}

.success {
  background-color: green;
  border: 1px solid darkgreen;
}

.error {
  background-color: red;
  border: 1px solid darkred;
}

.submit-btn:disabled {
  background: grey;
  cursor: not-allowed;
}