.InventoryPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.InventoryPage .CarCard {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
}

@media (max-width: 768px) {
  .InventoryPage .CarCard {
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .InventoryPage .CarCard {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.HeroSection {
  text-align: center;
}

.HeroSection h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
}

.HeroSection p {
  margin-bottom: 30px;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .HeroSection h1 {
    font-size: 1.5em;
  }

  .HeroSection p {
    font-size: 1em;
  }

  .CardContainer .CarCard {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.HeroSection a {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: #f0f0f0;
  color: #000; 
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.HeroSection a:hover {
  background-color: #e0e0e0;
}