.CarDetailsPage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  margin-top: 60px;
}

.CarDetailsPageDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.CarDetailsPageDetails h2 {
  margin-bottom: 10px;
}

.CarDetailsPageDetails p,
.CarDetailsPageDetails ul {
  margin-bottom: 10px;
}

.CarDetailsPageDetails ul {
  list-style: none;
  padding: 0;
}

.CarDetailsPageDetails li {
  padding: 5px;
  border-radius: 5px;
}

.CarDetailsPage a {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: #f0f0f0;
  color: #000; 
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.CarDetailsPage a:hover {
  background-color: #e0e0e0;
}

.CarDetailsPage a {
  display: block;
}

.CarDetailsImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
}

.CarDetailsImage img {
  max-width: 100%;
  height: auto;
}

.ImageNavigationButtons {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.CarouselButton {
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.CarouselButton:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .CarDetailsPage {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .CarDetailsImageContainer {
    width: 100%;
    max-width: 800px;
  }

  .CarDetailsImage img {
    width: 100%;
    height: auto;
  }

  .ImageNavigationButtons {
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
  }

  .CarDetailsPageDetails {
    width: 100%;
    max-width: 800px;
    order: 2;
  }

  .CarDetailsPageDetails h2,
  .CarDetailsPageDetails p,
  .CarDetailsPageDetails ul,
  .CarDetailsPageDetails a {
    text-align: center;
    margin: 0;
  }

  .CarDetailsPageDetails ul {
    margin-top: 15px;
    padding-left: 0;
  }

  .CarDetailsPage a {
    width: auto;
    margin: 10px auto;
  }
}
