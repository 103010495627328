.HomePageBanner {
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 20px;
}

.CardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.CardContainer .CarCard {
  flex: 1 0 calc(50% - 20px);
  max-width: calc(50% - 20px);
}

@media (max-width: 768px) {
  .CardContainer .CarCard {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.HomePage a {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: #f0f0f0;
  color: #000; 
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.HomePage a:hover {
  background-color: #e0e0e0;
}

.HomePage a {
  display: block;
}

.HeroSection {
  text-align: center;
}

.HeroSection h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
}

.HeroSection p {
  margin-bottom: 30px;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .HeroSection h1 {
    font-size: 1.5em;
  }

  .HeroSection p {
    font-size: 1em;
  }

  .CardContainer .CarCard {
    flex-basis: 100%;
    max-width: 100%;
  }
}