.CarCard {
  display: flex;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 10px 0;
}

.CarCard.horizontal {
  flex-direction: row;
  align-items: center;
}

.CarCard.vertical {
  flex-direction: column;
}

.CarCard.horizontal .CarImage img {
  max-height: 250px; /* Ensures consistent height for horizontal cards */
  width: auto; /* Maintain aspect ratio */
}

.CarCard.vertical .CarImage img {
  max-width: 300px;
  height: auto;
}

.CarDetails {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  position: relative;
}

.CarDetails p {
  padding: 0px;
  margin: 0px;
}

.CarDetails h3 {
  position: relative;
  top: 0;
  padding: 0px;
  margin: 0px 0px 10px;
}

.CarActions {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

/* .CarActions a {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
  text-decoration: none;
} */

.CarActions a {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  text-decoration: none; /* Remove the underline from links */
  background-color: #f0f0f0;
  color: #000; /* Set text color */
  text-align: center; /* Center the text in the link */
  border-radius: 4px; /* Optional: if you want rounded corners */
  transition: background-color 0.3s;
}

.CarActions a:hover {
  background-color: #e0e0e0;
}

.CarActions a {
  display: block;
}